* {
  box-sizing: border-box;
}

:root {
  --bg: rgb(9, 14, 23);
  --primary: turquoise;
  --gradientBg: linear-gradient(45deg, #40e0d0, #9932cc, #ff1493, orange);
}

.content {
  font-family: Montserrat, sans-serif;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background-color: var(--bg);
  color: #ffffff;
}

/* Reset */
.content a {
  color: inherit;
}

.content ul {
  list-style: none;
}

.content button {
  border: none;
  padding: 0.5rem;
  font-size: inherit;
  cursor: pointer;
}

.wrapper {
  position: relative;
  width: 100%;
}

.container {
  width: 100%;
  max-width: 84rem;
  margin: 0 auto;
}

/* Text */
.hero__heading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: clamp(2rem, 8vw, 8rem);
  line-height: 1.1;
  font-weight: 900;
  margin: 0;
}

.hero--primary .hero__heading {
  -webkit-text-stroke: 2px var(--primary);
  color: transparent;
  filter: drop-shadow(0 0 0.35rem var(--primary));
}

.hero--secondary .hero__heading {
  background: radial-gradient(circle at center, #ffffff 0.11rem, transparent 0);
  background-size: 0.4rem 0.4rem;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

/* Menu */
.menu {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  background: var(--gradientBg);
  clip-path: circle(0 at calc(100% - 2rem) 2rem);
  transition: clip-path 500ms;
  z-index: 1;
  font-size: clamp(1rem, 3vw, 4rem);
}

.menu.is-open {
  clip-path: circle(200% at calc(100% - 2rem) 2rem);
  pointer-events: all;
}

.menu a {
  display: block;
  padding: 0.5em;
  text-decoration: none;
  font-weight: 900;
}

.menu a:hover,
.menu a:focus {
  text-decoration: underline;
}

.menu-button {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--bg);
  color: white;
  padding: 0.75rem;
  width: 3.5rem;
  height: 3.5rem;
  top: 1rem;
  right: 1rem;
  z-index: 2;
  border-radius: 50%;
}

.menu-button__icon {
  display: block;
  position: relative;
  overflow: visible;
}

.menu-button__icon span,
.menu-button__icon::before,
.menu-button__icon::after {
  display: block;
  width: 2rem;
  height: 4px;
  background-color: white;
}

.menu-button__icon::before,
.menu-button__icon::after {
  content: "";
  position: absolute;
  left: 0;
  top: -8px;
  transition: transform 200ms;
  transform-origin: center;
  pointer-events: none;
}

.menu-button__icon::after {
  bottom: -8px;
  top: auto;
}

.menu-button.is-active .menu-button__icon > span {
  opacity: 0;
}

.menu-button.is-active .menu-button__icon::before {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}

.menu-button.is-active .menu-button__icon::after {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
}

/* Hero */
.hero {
  --x: 50%;
  --y: 50%;
  min-height: 100vh;
  padding: clamp(1rem, 2vw, 5rem);
  display: flex;
  align-items: center;
}

.hero--secondary {
  --mask: radial-gradient(
    circle at var(--x, 50%) var(--y, 50%),
    black var(--maskSize1, 0%),
    rgba(0, 0, 0, 0.1) calc(var(--maskSize1, 0%) + 0.1%),
    transparent 0,
    transparent var(--maskSize2, 0%),
    rgba(0, 0, 0, 0.1) calc(var(--maskSize2, 0%) + 0.1%),
    black var(--maskSize2, 0%),
    rgba(0, 0, 0, 0.1) calc(var(--maskSize3, 0%) - 0.1%),
    black var(--maskSize3, 0%),
    rgba(0, 0, 0, 0.1) calc(var(--maskSize3, 0%) + 0.1%),
    transparent 0
  );
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: var(--gradientBg);
  color: rgb(9, 14, 23);
  -webkit-mask-image: var(--mask);
  mask-image: var(--mask);
}

/* Text animation */
.hero--primary .hero__heading .char {
  display: inline-block;
}
